import Profile from '../../../../models/profile/Profile';
import AuthorModel from '../../../../models/v2/author/author.model';
import { OddClientBookmakersFullModel } from '../../../Partials/Blocky/partials/available-bookmakers-select/models/odd-client-bookmakers-full.model';
import { ContentBlockData, HtmlBlockData, JSONData, WidgetBlockData } from '../helpers/construct-post-json-data';

export enum PublishedType {
	DRAFT = 'DRAFT',
	PUBLISHED = 'PUBLISHED',
}

export enum SportEventTypeCode {
	GOAL = 'GOAL',
	OWN_GOAL = 'OWN_GOAL',
	PENALTY_GOAL = 'PENALTY_GOAL',
	PENALTY_MISS = 'PENALTY_MISS',
	PENALTY_SHOOTOUT_SCORED = 'PENALTY_SHOOTOUT_SCORED',
	PENALTY_SHOOTOUT_MISSED = 'PENALTY_SHOOTOUT_MISSED',
	YELLOW_CARD = 'YELLOW_CARD',
	YELLOW_CARD_RED = 'YELLOW_CARD_RED',
	RED_CARD = 'RED_CARD',
	ASSIST = 'ASSIST',
	SUBSTITUTION = 'SUBSTITUTION',
	VAR = 'VAR',
	SHOTOFF_POST = 'SHOTOFF_POST',
	KICKOFF = 'KICKOFF',
	FIRST_HALF = 'FIRST_HALF',
	HALF_TIME = 'HALF_TIME',
	SECOND_HALF = 'SECOND_HALF',
	FULL_TIME = 'FULL_TIME',
	INTERRUPTED = 'INTERRUPTED',
	ABANDONED = 'ABANDONED',
	FINISHED_AET = 'FINISHED_AET',
	PENALTY_SHOOTOUT = 'PENALTY_SHOOTOUT',
	FINISHED_AP = 'FINISHED_AP',
	FIRST_HALF_EXTRA_TIME = 'FIRST_HALF_EXTRA_TIME',
	SECOND_HALF_EXTRA_TIME = 'SECOND_HALF_EXTRA_TIME',
	WAITING_EXTRA_TIME = 'WAITING_EXTRA_TIME',
	WAITING_PENALTY_SHOOT_OUT = 'WAITING_PENALTY_SHOOT_OUT',
	END_FIRST_HALF_EXTRA_TIME = 'END_FIRST_HALF_EXTRA_TIME',
	PLAY_STOPPED = 'PLAY_STOPPED',
	PLAY_RESTARTED = 'PLAY_RESTARTED',
	INJURY_TIME = 'INJURY_TIME',
	POSSESSION = 'POSSESSION',
}

export interface LiveBlogPostModel {
	id: string;
	body: string;
	minute?: string | null;
	injury_minute?: string | null;
	author?: Pick<AuthorModel, 'id' | 'name'> | null;
	sport_event?: (string | { id: string }) | null;
	sport_tags?: { id: string & Record<string, any> }[] | null;
	sponsors?: OddClientBookmakersFullModel[] | null;
	published_type: PublishedType;
	is_highlight?: boolean;
	is_pinned?: boolean;
	external_id?: string | null;
	created_at: string; // ISO 8601 timestamp
	updated_at: string; // ISO 8601 timestamp
	display_timestamp: string; // ISO 8601 timestamp
	created_by?: Pick<Profile, 'id' | 'name'>;
	sport_event_type?: {
		code: SportEventTypeCode;
	};
	blocks: JSONData<HtmlBlockData | ContentBlockData | WidgetBlockData>[];
}

export type LiveBlogPostCreatePayload = Omit<LiveBlogPostModel, 'id' | 'created_at' | 'updated_at' | 'created_by' | 'sport_event_type'>;

export type LiveBlogPostUpdatePayload = Omit<LiveBlogPostCreatePayload, 'created_by'>;

export interface LiveBlogPostsFilter {
	limit?: number;
	page?: number;
	published_type?: LiveBlogPostModel['published_type'];
	is_highlight?: LiveBlogPostModel['is_highlight'];
	is_pinned?: LiveBlogPostModel['is_pinned'];
}

export interface LiveBlogPostsResponse {
	data: LiveBlogPostModel[];
	meta: { count: number; current_page: number; per_page: number; total: number; total_pages: number };
}
