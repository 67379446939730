import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import TennisCompetitionSeasonModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import TennisTournamentModel from '../../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import TennisRoundModel from '../../../../../../../models/v2/round/tennis-round/tennis-round.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { TennisTournamentProgrammeJson } from './tennis-tournament-programme.json';
import TennisTournamentProgrammeModel from './tennis-tournament-programme.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class TennisTournamentProgrammeBuilder {
	readonly json: TennisTournamentProgrammeJson;

	constructor(tournamentProgramme?: TennisTournamentProgrammeModel | TennisTournamentProgrammeJson) {
		if (tournamentProgramme && tournamentProgramme instanceof TennisTournamentProgrammeModel) {
			this.json = tournamentProgramme.toJson();
		} else if (tournamentProgramme) {
			this.json = tournamentProgramme;
		} else {
			this.json = {} as TennisTournamentProgrammeJson;
		}
	}

	withCompetition(competition: CompetitionModel): TennisTournamentProgrammeBuilder {
		this.json.competition = competition;

		return this;
	}

	withSeason(season: TennisCompetitionSeasonModel): TennisTournamentProgrammeBuilder {
		this.json.season = season;

		return this;
	}

	withTournament(tournament: TennisTournamentModel): TennisTournamentProgrammeBuilder {
		this.json.tournament = tournament;

		return this;
	}

	withHeaderDefaultOption(headerDefaultOption: string | null): TennisTournamentProgrammeBuilder {
		this.json.headerDefaultOption = headerDefaultOption;

		return this;
	}

	withTournaments(tournaments: TennisTournamentModel[]): TennisTournamentProgrammeBuilder {
		this.json.tournaments = tournaments;

		return this;
	}

	withRounds(rounds: TennisRoundModel[]): TennisTournamentProgrammeBuilder {
		this.json.rounds = rounds;

		return this;
	}

	withAvailableRounds(availableRounds: TennisRoundModel[]): TennisTournamentProgrammeBuilder {
		this.json.availableRounds = availableRounds;

		return this;
	}

	withSortDirectionResults = (sortDirectionResults: string): TennisTournamentProgrammeBuilder => {
		this.json.sortDirectionResults = sortDirectionResults;

		return this;
	};

	withSortDirectionFixtures = (sortDirectionFixtures: string): TennisTournamentProgrammeBuilder => {
		this.json.sortDirectionFixtures = sortDirectionFixtures;

		return this;
	};

	withDisplayOdds(displayOdds: boolean): TennisTournamentProgrammeBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): TennisTournamentProgrammeBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): TennisTournamentProgrammeBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	withDisplayHeader(displayHeader: boolean): TennisTournamentProgrammeBuilder {
		this.json.displayHeader = displayHeader;

		return this;
	}

	build(): TennisTournamentProgrammeModel {
		return TennisTournamentProgrammeModel.fromJson(this.json);
	}
}
