import React, { ChangeEvent, FunctionComponent } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface Properties {
	value: boolean;
	onDisplayHeaderChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const HeaderDisplayInput: FunctionComponent<Properties> = ({ value, onDisplayHeaderChange }) => {
	const { t } = useTranslation();

	return (
		<FormGroup data-qa='header-display-select' check className='radio'>
			<Input
				className='form-check-input'
				type='checkbox'
				id='widget-header-display-select'
				name='radios'
				onChange={onDisplayHeaderChange}
				checked={value ? value : false}
			/>
			<Label htmlFor='header-display-select' check className='form-check-label'>
				{t('header_display')}
			</Label>
		</FormGroup>
	);
};

export default HeaderDisplayInput;
