import TeamProgrammeWidgetBuilder from './team-programme-widget.builder';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import { TeamProgrammeWidgetJson } from './team-programme-widget.json';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class TeamProgrammeWidgetModel {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly stage: StageModel;
	readonly rounds: RoundModel[];
	readonly team: TeamModel;
	readonly sortDirectionFixtures: string;
	readonly sortDirectionResults: string;
	readonly matchType: any;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly headerDefaultOption: string | null;
	readonly refreshTime: RefreshTimeValuesType;
	readonly displayHeader: boolean = false;

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		stage: StageModel,
		rounds: RoundModel[],
		team: TeamModel,
		sortDirectionFixtures: string,
		sortDirectionResults: string,
		matchType: any,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		headerDefaultOption: string | null,
		refreshTime: RefreshTimeValuesType,
		displayHeader: boolean,
	) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.rounds = rounds;
		this.team = team;
		this.sortDirectionFixtures = sortDirectionFixtures;
		this.sortDirectionResults = sortDirectionResults;
		this.matchType = matchType;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.headerDefaultOption = headerDefaultOption;
		this.refreshTime = refreshTime;
		this.displayHeader = displayHeader;
	}

	toJson(): TeamProgrammeWidgetJson {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			rounds: this.rounds,
			team: this.team,
			sortDirectionFixtures: this.sortDirectionFixtures,
			sortDirectionResults: this.sortDirectionResults,
			matchType: this.matchType,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			headerDefaultOption: this.headerDefaultOption,
			refreshTime: this.refreshTime,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: TeamProgrammeWidgetJson): TeamProgrammeWidgetModel {
		return new TeamProgrammeWidgetModel(
			json.tournament,
			json.season,
			json.stage,
			json.rounds,
			json.team,
			json.sortDirectionFixtures,
			json.sortDirectionResults,
			json.matchType,
			json.displayOdds,
			json.bookmakers,
			json.headerDefaultOption,
			json.refreshTime,
			json.displayHeader,
		);
	}

	static builder(model?: TeamProgrammeWidgetModel): TeamProgrammeWidgetBuilder {
		return new TeamProgrammeWidgetBuilder(model);
	}
}
