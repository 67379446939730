import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import TournamentProgrammeWidgetModel from './football-tournament-programme.model';
import { TournamentProgrammeWidgetJson } from './football-tournament-programme.json';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class TournamentProgrammeWidgetBuilder {
	readonly json: TournamentProgrammeWidgetJson;

	constructor(model?: TournamentProgrammeWidgetModel | TournamentProgrammeWidgetJson) {
		if (model && model instanceof TournamentProgrammeWidgetModel) {
			this.json = model.toJson();
		} else if (model) {
			this.json = model;
		} else {
			this.json = {} as TournamentProgrammeWidgetJson;
		}
	}

	withTournament = (tournament: TournamentModel): TournamentProgrammeWidgetBuilder => {
		this.json.tournament = tournament;

		return this;
	};

	withSeason = (season: SeasonModel): TournamentProgrammeWidgetBuilder => {
		this.json.season = season;

		return this;
	};

	withStage = (stage: StageModel): TournamentProgrammeWidgetBuilder => {
		this.json.stage = stage;

		return this;
	};

	withHeaderDefaultOption(headerDefaultOption: string | null): TournamentProgrammeWidgetBuilder {
		this.json.headerDefaultOption = headerDefaultOption;

		return this;
	}

	withRound = (rounds: RoundModel[]): TournamentProgrammeWidgetBuilder => {
		this.json.rounds = rounds;

		return this;
	};

	withSortDirectionResults = (sortDirectionResults: string): TournamentProgrammeWidgetBuilder => {
		this.json.sortDirectionResults = sortDirectionResults;

		return this;
	};

	withSortDirectionFixtures = (sortDirectionFixtures: string): TournamentProgrammeWidgetBuilder => {
		this.json.sortDirectionFixtures = sortDirectionFixtures;

		return this;
	};

	withDisplayOdds(displayOdds: boolean): TournamentProgrammeWidgetBuilder {
		this.json.displayOdds = displayOdds;

		return this;
	}

	withBookmakers(bookmakers: SportOddsBookmakerModel[] | null): TournamentProgrammeWidgetBuilder {
		this.json.bookmakers = bookmakers;

		return this;
	}

	withRefreshTime(refreshTime: RefreshTimeValuesType): TournamentProgrammeWidgetBuilder {
		this.json.refreshTime = refreshTime;

		return this;
	}

	withDisplayHeader(displayHeader: boolean): TournamentProgrammeWidgetBuilder {
		this.json.displayHeader = displayHeader;

		return this;
	}

	build(): TournamentProgrammeWidgetModel {
		return TournamentProgrammeWidgetModel.fromJson(this.json);
	}
}
